
import { defineComponent } from "vue";
import { TokenModel } from "@/store/member";
import {
  email,
  sameAs,
  helpers,
  minLength,
  maxLength,
  required,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "vuex";
import { RequestParams, RequestResponse } from "@/plugins/iamport";
import BaseGlobalLoading from "@/components/BaseGlobalLoading.vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import InputBox from "@/components/member/InputBox.vue";
import CheckBox from "@/components/common/CheckBox.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import AlertCircleIcon from "icons/AlertCircle.vue";
import pg from "@/data/pg";

const isAllowedChar = helpers.regex(
  //eslint-disable-next-line
  /^[a-zA-Z0-9$@$!%*?&:;<=>%+-.,()~`#"'\[\]^_\|{}\\]*$/
);

const hasSpecialChar = helpers.regex(
  //eslint-disable-next-line
  /(?=.*[$@$!%*?&:;<=>%+-.,()~`#"'\[\]^_|{}\\])/
);

const hasNumber = helpers.regex(/(?=.*[0-9])/);

const hasAlpha = helpers.regex(/(?=.*[a-zA-Z])/);

export default defineComponent({
  name: "DefaultSignup",
  components: {
    BaseGlobalLoading,
    BaseHeader2,
    InputBox,
    CheckBox,
    DefaultButton,
    AlertCircleIcon,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const store = useStore();
    return {
      store,
      email: "",
      password: "",
      password2: "",
      isMarketing: false,
      merchant_uid: "",
      isCertificate: false,
      vuelidateExternalResults: {
        email: [],
      },
    };
  },
  validations() {
    return {
      email: { required, email, isUsableEmail: this.isUsableEmail },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        isAllowedChar,
        hasNumber,
        hasSpecialChar,
        hasAlpha,
      },
      password2: {
        sameAsPassword: sameAs(this.password),
      },
    };
  },
  computed: {
    isFormValid() {
      return !this.v$.$invalid && !this.v$.$error;
    },
  },
  methods: {
    loginKakao() {
      this.$flutter.callHandler("loginKakao");
    },
    async isUsableEmail(email: string) {
      if (email) {
        let isValid = await this.$axios.get("/member/availability", {
          params: { email },
        });

        if (!isValid.data.email) {
          const errors = { email: ["isUsableEmail"] };
          Object.assign(this.vuelidateExternalResults, errors);
          return;
        }
      }
      const errors = { email: [] };
      Object.assign(this.vuelidateExternalResults, errors);
    },
    doCertificate() {
      if (window.IMP) {
        window.IMP.init(pg.iamportId);
      }

      this.$axios
        .post("/member/certificate/issue")
        .then((res) => {
          this.callPGCertificate(res.data.merchant_uid);
        })
        .catch((e) => {
          alert(e);
          this.$flutter.callHandler("showToast", {
            message: "인증에 실패하였습니다. (1)",
            color: "error",
          });
        });
    },
    callPGCertificate(merchant_uid: string) {
      this.merchant_uid = merchant_uid;
      if (process.env.is_develop) {
        this.isCertificate = true;
        return;
      }

      const data: RequestParams = {
        merchant_uid: merchant_uid,
      };
      const pgCallbackCertificate = (response: RequestResponse) => {
        let data = {
          raw: response,
        };

        this.$axios
          .post("/member/certificate/process", data)
          .then(() => {
            if (response.success) {
              this.store.commit("main/setIsLoading", true);
              this.isCertificate = true;
              this.$flutter.callHandler("showToast", {
                message: "인증에 성공하였습니다.",
                color: "success",
              });
              this.singUp();
            } else {
              this.$flutter.callHandler("showToast", {
                message: "인증에 실패하였습니다. (2)",
                color: "error",
              });
            }
          })
          .catch(() => {
            this.$flutter.callHandler("showToast"),
              {
                message: "잠시후 다시 시도해주세요.",
              };
          });
      };

      window.IMP.certification(data, pgCallbackCertificate);
    },
    singUp() {
      this.$axios
        .post("/member", {
          email: this.email,
          password: this.password,
          password2: this.password2,
          merchant_uid: this.merchant_uid,
          agree3: this.isMarketing,
        })
        .then(() => {
          this.$pushGAEvent("sign_up_complete", {
            method: "email",
          });
          this.signIn();
        });
    },
    signIn() {
      this.$axios
        .post("/token", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          const data: TokenModel = res.data;
          this.email = "";
          this.password = "";

          this.$injectToken(data.access);
          this.store.dispatch("member/updateToken", data).then(() => {
            this.$flutter.callHandler("updateToken", {
              access: data.access,
              refresh: data.refresh,
            });
          });
          this.store.commit("main/setIsLoading", false);
          this.$flutter.callHandler("selectGnb", {
            path: "/",
          });
        });
    },
  },
  created() {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");
    script1.setAttribute("src", "https://code.jquery.com/jquery-1.12.4.min.js");
    script2.setAttribute(
      "src",
      "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js"
    );
    document.head.appendChild(script1);
    document.head.appendChild(script2);
  },
});
