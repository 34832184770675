
import { defineComponent } from "vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedIcon from "icons/CheckboxMarked.vue";
import LockOpenOutlineIcon from "icons/LockOpenOutline.vue";

export default defineComponent({
  name: "CheckBox",
  components: {
    CheckboxBlankOutlineIcon,
    CheckboxMarkedIcon,
    LockOpenOutlineIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCheckIcon: {
      type: Boolean,
      default: true,
    },
    disabledLock: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    change(e: InputEvent) {
      this.$emit("update:modelValue", (e.target as HTMLInputElement).checked);
    },
  },
});
