import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d1ace99"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxBlankOutlineIcon = _resolveComponent("CheckboxBlankOutlineIcon")!
  const _component_CheckboxMarkedIcon = _resolveComponent("CheckboxMarkedIcon")!
  const _component_LockOpenOutlineIcon = _resolveComponent("LockOpenOutlineIcon")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["checkbox-label", { disabled: _ctx.disabled }])
  }, [
    (_ctx.isCheckIcon)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.disabled)
            ? (_openBlock(), _createBlock(_component_CheckboxBlankOutlineIcon, {
                key: 0,
                class: "btn-check",
                fillColor: "var(--grey1-color)"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.modelValue)
                  ? (_openBlock(), _createBlock(_component_CheckboxMarkedIcon, {
                      key: 0,
                      class: "btn-check",
                      fillColor: "var(--blue1-color)"
                    }))
                  : (_openBlock(), _createBlock(_component_CheckboxBlankOutlineIcon, {
                      key: 1,
                      class: "btn-check",
                      fillColor: "var(--blue2-color)"
                    }))
              ], 64))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.modelValue)
            ? (_openBlock(), _createBlock(_component_LockOpenOutlineIcon, {
                key: 0,
                size: 15,
                fillColor: "var(--blue1-color)"
              }))
            : (_openBlock(), _createBlock(_component_LockOpenOutlineIcon, {
                key: 1,
                size: 15,
                fillColor: "var(--grey2-color)"
              }))
        ], 64)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("input", {
      checked: _ctx.modelValue,
      type: "checkbox",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_1)
  ], 2))
}