import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3aa6d1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-box" }
const _hoisted_2 = ["v-if"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "input-len"
}
const _hoisted_5 = ["type", "id", "placeholder", "value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      "v-if": _ctx.labelText,
      class: "input-text"
    }, [
      _createElementVNode("label", {
        class: "input-label",
        for: _ctx.inputId
      }, _toDisplayString(_ctx.labelText), 9, _hoisted_3),
      (_ctx.inputText && _ctx.lenText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.inputText), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        class: "input-area",
        type: _ctx.inputType,
        id: _ctx.inputId,
        placeholder: _ctx.inputPlaceholder,
        value: _ctx.modelValue,
        maxlength: _ctx.inputLength,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inputFocus && _ctx.inputFocus(...args)))
      }, null, 40, _hoisted_5)
    ])
  ]))
}