
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputBox",
  components: {},
  props: {
    labelText: {
      type: String,
    },
    inputText: {
      type: String,
    },
    inputType: {
      type: String,
      default: "text",
    },
    inputId: {
      type: String,
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
    inputLength: {
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      lenText: false,
    };
  },
  methods: {
    inputFocus() {
      this.lenText = true;
    },
  },
});
